import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { SidebarState } from 'src/Redux/sidebar-process'

import { RootState } from '..'

const getState = (state: RootState): SidebarState => state.sidebar

export const getSidebar = createDraftSafeSelector(
  getState,
  (sidebar) => sidebar,
)
export const getCollapse = createDraftSafeSelector(
  getState,
  ({ collapse }) => collapse,
)

export const getVisitedPages = createDraftSafeSelector(
  getState,
  ({ visitedNewPages }) => visitedNewPages,
)

export const getIsPageVisited =
  (page: string) =>
  (state: RootState): boolean | undefined =>
    getVisitedPages(state)?.includes(page)
