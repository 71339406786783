import React, { useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SecureIcon from 'src/Assets/Svg/pages-icons/Secure.svg?react'
import logo from 'src/Assets/Png/logo.png'
import { APP } from 'src/Configs/App'
import {
  locationEvent,
  curatorRegistrationStartEvent,
} from 'src/Helpers/TagManager'
import { playlistRequirementsLink } from 'src/Constants/links'
import { SpotifyLinkButton } from 'src/Components/Buttons/SpotifyLinkButton'

import {
  SignUpContainer,
  InnerSignUpContainer,
  SignUpLogo,
  SignUpBlock,
  SignUpTitle,
  SignUpSubTitle,
  SafetyBlock,
  InfoText,
  Condition,
  AlreadyHaveAccount,
} from './styles'

const START_INDEX = 8

const SignUp: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const role = location.pathname.slice(START_INDEX)

  useEffect(() => {
    locationEvent()

    if (role.includes('curator')) {
      curatorRegistrationStartEvent()
    }
  }, [role])

  return (
    <SignUpContainer>
      <InnerSignUpContainer>
        <SignUpLogo>
          <img alt='logo' src={logo} />
        </SignUpLogo>
        <SignUpBlock>
          <SignUpTitle>
            {t('signup.become')} {t('sidebar.curator')}
          </SignUpTitle>

          <SignUpSubTitle>{t('signup.getPaid')}</SignUpSubTitle>

          <SpotifyLinkButton href={APP.SPOTIFY_AUTH_API}>
            {t('signup.connectWithSpotify')}
          </SpotifyLinkButton>

          <SafetyBlock>
            <SecureIcon />
            <InfoText>{t('signup.yourData')}</InfoText>
          </SafetyBlock>

          {role.includes('curator') && (
            <Condition>
              {t('signup.agreement')}&nbsp;
              <a href={playlistRequirementsLink} target='_blank'>
                {t('signup.termsConditions')}&nbsp;
              </a>
            </Condition>
          )}

          <AlreadyHaveAccount>
            <a href='/login'>{t('signup.alreadyHaveAccount')}</a>
          </AlreadyHaveAccount>
        </SignUpBlock>
      </InnerSignUpContainer>
    </SignUpContainer>
  )
}

export default SignUp
