import React, { PropsWithChildren } from 'react'

import styled from 'styled-components'

import Spotify from 'src/Assets/Svg/platform-icons/white_Spotify.svg?react'

export const StyledLink = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.button.thirdBg};
  font-family: 'Segoe UI';
  color: ${({ theme }) => theme.button.primaryBg};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  align-items: center;
  max-width: 281px;
  margin: 0 auto;
  border-radius: 27px;
  padding: 9.5px 39px;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.button.primaryBg};
  }
`
const ChildrenWrapper = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 8px;
  > span {
    display: flex;
    align-self: center;
  }
`

export type TextLinkProps = PropsWithChildren &
  React.AnchorHTMLAttributes<HTMLAnchorElement>

export const SpotifyLinkButton: React.FC<TextLinkProps> = ({
  children,
  ...props
}) => (
  <StyledLink {...props}>
    <ChildrenWrapper>
      <span>
        <Spotify />
      </span>

      <span>{children}</span>
    </ChildrenWrapper>
  </StyledLink>
)
