import React, { FC } from 'react'

import { Rate, RateProps } from 'antd'
import type { StarProps } from 'rc-rate/lib/Star'

import CheckedStar from 'src/Assets/Svg/rateStars/checkedStar.svg?react'
import UncheckedStar from 'src/Assets/Svg/rateStars/uncheckedStar.svg?react'

export type RateStarsProps = RateProps

const DEFAULT_COUNT = 5

export const RateStars: FC<RateStarsProps> = (props) => (
  <Rate
    allowClear={false}
    character={(e: StarProps) => {
      if (typeof e.index === 'number' && typeof e.value === 'number') {
        return e.index < e.value ? <CheckedStar /> : <UncheckedStar />
      }
      return null
    }}
    {...props}
    count={DEFAULT_COUNT}
  />
)
