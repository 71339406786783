import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { NameSpace } from '../types'

export interface SidebarState {
  show: boolean
  tabletShow: boolean
  collapse: boolean
  visitedNewPages: Array<string>
}

const initialState: SidebarState = {
  show: false,
  collapse: false,
  tabletShow: true,
  visitedNewPages: [],
}

export const sidebarProcess = createSlice({
  initialState,
  name: NameSpace.Sidebar,
  reducers: {
    changeSidebarVisibility(state) {
      state.collapse = !state.collapse
    },
    changeMobileSidebarVisibility(state) {
      state.show = !state.show
    },
    hideTabletSidebar(state) {
      state.tabletShow = false
    },
    showTabletSidebar(state) {
      state.tabletShow = true
    },
    hideSidebar(state) {
      state.collapse = true
    },
    setVisitedNewPages(state, { payload }: PayloadAction<string>) {
      state.visitedNewPages.push(payload)
    },
  },
})

export const {
  changeSidebarVisibility,
  changeMobileSidebarVisibility,
  hideSidebar,
  setVisitedNewPages,
  hideTabletSidebar,
  showTabletSidebar,
} = sidebarProcess.actions
