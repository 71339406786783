import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useAppSelector } from 'src/Hooks/redux'
import { msgLowReachReceived } from 'src/Helpers/TagManager/ScheduleEvents'
import {
  getUserCampaignTypeSelector,
  getUserId,
} from 'src/Redux/auth-process/userSlice/selectors'
import {
  getCampaignToSchedule,
  getIsSelectedLanguagesWithoutAny,
  getGenresListSelector,
  getLanguagesListSelector,
} from 'src/Redux/campaign-process/spotifyCampaignSchedule-process/selectors'
import { getAmountOfCampaigns } from 'src/Redux/campaignList-process'
import { useGetBudgetRangeQuery } from 'src/Redux/spotifyBudgetRangeApi'

export const useScheduleGenres = (): void => {
  const { number: campaignId } = useParams()
  const { isError: budgetRangeError } = useGetBudgetRangeQuery(campaignId, {
    skip: !campaignId,
  })
  const userId = useSelector(getUserId)
  const scheduledSpotifyCampaign = useSelector(getCampaignToSchedule)
  const genreList = useSelector(getGenresListSelector)
  const languageList = useSelector(getLanguagesListSelector)
  const campaignType = useSelector(getUserCampaignTypeSelector)
  const amountOfCampaigns = useSelector(getAmountOfCampaigns)
  const hasSelectedLanguages = useAppSelector(getIsSelectedLanguagesWithoutAny)

  useEffect(() => {
    if (budgetRangeError && hasSelectedLanguages) {
      msgLowReachReceived({
        genreList,
        languageList,
        type: campaignType,
        campaignId: String(scheduledSpotifyCampaign.id),
        userId: String(userId),
        campaignNumber: amountOfCampaigns,
      })
    }
  }, [
    amountOfCampaigns,
    budgetRangeError,
    campaignType,
    genreList,
    hasSelectedLanguages,
    languageList,
    scheduledSpotifyCampaign.id,
    userId,
  ])
}
