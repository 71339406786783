import React, { ReactNode } from 'react'

import { useSelector } from 'react-redux'

import { getCollapse } from 'src/Redux/sidebar-process/selectors'

import { StyledPageContainer, InnerWrapper } from './styles'

export interface PageContainerProps {
  children: ReactNode
}

const PageContainer: React.FC<PageContainerProps> = ({ children }) => {
  const isMenuClosed = useSelector(getCollapse)

  return (
    <StyledPageContainer isMenuOpened={!isMenuClosed}>
      <InnerWrapper>{children}</InnerWrapper>
    </StyledPageContainer>
  )
}
export default PageContainer
