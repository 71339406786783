import { FunctionComponent, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { changeMobileSidebarVisibility } from 'src/Redux/sidebar-process'
import { getTotalAwaitingTracksSelector } from 'src/Redux/reviews-process/selectors'
import { getTotalAwaitingTracksSelector as getTotalFeedReviews } from 'src/Redux/feed-process/selectors'
import IconHelpCenter from 'src/Assets/Svg/helpCenter.svg?react'
import { getUserRole } from 'src/Redux/auth-process/credentialsSlice/selectors'
import {
  getArtistBalance,
  getIsNewFeed,
  getUserEmail,
} from 'src/Redux/auth-process/userSlice/selectors'
import { getHasActiveOrCanceledSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'

import SidebarLink from '../SidebarLink'

import { getMenu } from './helpers'

import {
  SidebarContentWrapper,
  SidebarList,
  SidebarLinkItem,
  SidebarIconBlock,
  SidebarLinkText,
  StyledNavLink,
} from './styles'

interface SidebarContentProps {
  collapse: boolean
}

const FIXED_BY_TWO = 2
const SidebarContent: FunctionComponent<SidebarContentProps> = ({
  collapse,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const email = useAppSelector(getUserEmail)
  const hasPlan = useAppSelector(getHasActiveOrCanceledSubscriptionSelector)
  const userRole = useAppSelector(getUserRole)
  const isNewFeed = useAppSelector(getIsNewFeed)
  const awaitingReviewTotal = useAppSelector(getTotalAwaitingTracksSelector)
  const feedReviewsTotal = useAppSelector(getTotalFeedReviews)
  const currentBalance = useAppSelector(getArtistBalance)
  const isTablet = useMediaQuery({ maxWidth: 1023 })

  const menu = useMemo(() => {
    if (!userRole) {
      return []
    }
    const value = {
      feedReviewsTotal,
      awaitingReviewTotal,
      isNewFeed: Boolean(isNewFeed),
      balance: currentBalance?.toFixed(FIXED_BY_TWO) || '0',
    }
    return getMenu({
      userRole,
      hasPlan,
      email,
      values: value,
    })
  }, [
    email,
    hasPlan,
    feedReviewsTotal,
    awaitingReviewTotal,
    isNewFeed,
    currentBalance,
    userRole,
  ])
  const onChangeMobileSidebarVisibility = (): void => {
    isTablet && dispatch(changeMobileSidebarVisibility())
  }

  return (
    <SidebarContentWrapper collapse={collapse}>
      <SidebarList>
        {menu.map((item) => (
          <SidebarLink key={item.route} {...item} />
        ))}
        <StyledNavLink
          href='https://help.soundcamps.com/en/'
          target='_blank'
          rel='noopener noreferrer'
          onClick={onChangeMobileSidebarVisibility}
        >
          <SidebarLinkItem collapse={collapse}>
            <SidebarIconBlock>
              <IconHelpCenter />
            </SidebarIconBlock>
            <SidebarLinkText collapse={!collapse}>
              {t('sidebar.helpCenter')}
            </SidebarLinkText>
          </SidebarLinkItem>
        </StyledNavLink>
      </SidebarList>
    </SidebarContentWrapper>
  )
}

export default SidebarContent
