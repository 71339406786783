import { FC, useMemo } from 'react'

import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import { Roles } from 'src/Constants/enums'

import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from '../../Styled/variables'

import { SidebarToggle } from './Components/SidebarToggle'
import { SidebarUserInfoContainer } from './SidebarUserInfoContainer'
import { SidebarMenuContainer } from './Components/SidebarMenuContainer'
import { SidebarButtons } from './Components/SidebarButtons'

type WrapperProps = {
  $show: boolean
}

const mobileStyles = css<WrapperProps>`
  @media (max-width: ${MAX_MOBILE_WIDTH}) {
    box-shadow: ${({ $show }) =>
      $show ? '4px 0px 16px 0px rgba(51, 51, 51, 0.24)' : 'none'};
  }
  ${({ $show }) => {
    if ($show) {
      return css`
        @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
          position: fixed;
          top: 0;
          left: 0;
          transform: translateX(0);
          transition: transform 1s ease-in-out;
          z-index: 2;
        }
      `
    }
    return css`
      @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        transform: translateX(-100%);
        transition: transform 1s ease-in-out;
      }
    `
  }}
`
const desktopStyles = css<WrapperProps>`
  @media screen and (min-width: ${MAX_TABLET_WIDTH}) {
    width: ${({ $show }) => (!$show ? '240px' : '56px')};
    transition: width 1s ease-in-out;
  }
`
const tabletStyles = css<WrapperProps>`
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) and (max-width: ${MAX_TABLET_WIDTH}) {
    width: ${({ $show }) => ($show ? '240px' : '56px')};
    position: ${({ $show }) => ($show ? 'fixed' : 'static')};
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Wrapper = styled.div<WrapperProps>`
  height: 100vh;
  z-index: 2;
  display: flex;
  width: 240px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  background: #000;
  box-shadow: 4px 0px 16px 0px rgba(51, 51, 51, 0.24);
  @media screen and (max-height: 650px) {
    overflow-y: scroll;
  }
  ${mobileStyles}
  ${tabletStyles}
  ${desktopStyles}
`

const Backdrop = styled.div<WrapperProps>`
  ${({ $show }) => {
    if ($show) {
      return css`
        position: fixed;
        top: 0;
        z-index: 1;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
      `
    }
    return css``
  }}
`

export interface SidebarProps {
  collapsed: boolean
  role: Roles
  showFeedback?: boolean
  showFeatureRequest?: boolean
  firstName?: string
  userCover?: string
  lastName?: string
  planName?: string
  isAdmin?: boolean
  onLogoClick?: () => void
  onFeedbackClick?: () => void
  onFeatureClick?: () => void
  onChatClick?: () => void
  className?: string
  attributeValue?: string | null
  email?: string
}

export const Sidebar: FC<SidebarProps> = (props) => {
  const {
    role,
    firstName,
    planName,
    lastName,
    userCover,
    collapsed,
    onLogoClick,
    onChatClick,
    onFeatureClick,
    onFeedbackClick,
    attributeValue,
    isAdmin,
    showFeatureRequest,
    showFeedback,
    className,
    email,
  } = props
  const isTablet = useMediaQuery({ query: `(max-width: ${MAX_TABLET_WIDTH})` })
  const isDesktop = useMediaQuery({ query: `(min-width: ${MAX_TABLET_WIDTH})` })
  const isMobile = useMediaQuery({ query: `(max-width: ${MAX_MOBILE_WIDTH})` })

  const collapsedElements = useMemo(() => {
    if (isDesktop) {
      return collapsed
    }
    if (isTablet && !isDesktop && !isMobile) {
      return !collapsed
    }
    return false
  }, [collapsed, isDesktop, isMobile, isTablet])

  return (
    <>
      <Wrapper $show={collapsed} className={className}>
        <SidebarToggle onClick={onLogoClick} collapsed={collapsedElements} />
        <SidebarUserInfoContainer
          role={role}
          firstName={firstName}
          userCover={userCover}
          lastName={lastName}
          hide={collapsedElements}
          planName={planName}
        />
        <SidebarMenuContainer
          role={role}
          isAdmin={isAdmin}
          hasPlan={Boolean(planName)}
          collapsed={collapsedElements}
        />
        <SidebarButtons
          showFeedback={showFeedback}
          showFeatureRequest={showFeatureRequest}
          collapsed={collapsedElements}
          onChatClick={onChatClick}
          attributeValue={attributeValue}
          onFeatureClick={onFeatureClick}
          onFeedbackClick={onFeedbackClick}
          email={email}
        />
      </Wrapper>
      <Backdrop onClick={onLogoClick} $show={collapsed && isTablet} />
    </>
  )
}
