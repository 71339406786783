import { FC } from 'react'

import styled from 'styled-components'
import { Radio, RadioChangeEvent } from 'antd'
import { useTranslation } from 'react-i18next'

import { PaymentMethodEnum } from 'src/Constants/enums'
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from 'src/Styled/variables'

import { Collapse } from '../Collapse'

import { StripeRadioButton } from './StripeRadioButton'
import { PaypalRadioButton } from './PaypalRadioButton'
import { CreditsRadioButton } from './CreditsRadioButton'

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.common.primaryBorder};
  background: ${({ theme }) => theme.common.primaryBg};
  box-shadow: 0px 0px 32px 0px rgba(51, 51, 51, 0.08);
  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    max-width: 324px;
  }
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}) {
    max-width: 100%;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.common.primaryText};
  font-family: 'Segoe UI';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`
const MethodsContainer = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`

const MoreMethodsContainer = styled.div`
  margin-top: 40px;
`

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`

const StyledCollapse = styled(Collapse)`
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .ant-collapse-header-text {
    color: ${({ theme }) => theme.common.primaryText};
    font-family: 'Segoe UI';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`
export interface SchedulePaymentMethodProps {
  paymentMethod: PaymentMethodEnum
  paypalEmail?: string
  isEnoughBalance: boolean
  hasSavePaypal: boolean
  onChangeHasSavePaypal: (value: boolean) => void
  onChange: (paymentMethod: PaymentMethodEnum) => void
}

export const SchedulePaymentMethod: FC<SchedulePaymentMethodProps> = (
  props,
) => {
  const { t } = useTranslation()
  const {
    paymentMethod,
    paypalEmail,
    isEnoughBalance,
    hasSavePaypal,
    onChange,
    onChangeHasSavePaypal,
  } = props

  const onChangePaymentOption = (e: RadioChangeEvent): void => {
    const currentPaymentMethod = e?.target?.value as PaymentMethodEnum
    onChange(currentPaymentMethod)
  }
  const showMoreMethods = paypalEmail && !isEnoughBalance
  const showCredits = isEnoughBalance
  const showStripe = !paypalEmail || isEnoughBalance
  return (
    <Container>
      <Title>{t('schedulePaymentMethod.select')}</Title>
      <StyledRadioGroup value={paymentMethod} onChange={onChangePaymentOption}>
        <MethodsContainer>
          {showCredits && <CreditsRadioButton />}
          {showStripe && <StripeRadioButton disabled={isEnoughBalance} />}
          <PaypalRadioButton
            value={
              paypalEmail
                ? PaymentMethodEnum.PAYPAL_BILLING_AGREEMENT
                : PaymentMethodEnum.PAYPAL
            }
            disabled={isEnoughBalance}
            paypalEmail={paypalEmail}
            paymentMethod={paymentMethod}
            hasSavePaypal={hasSavePaypal}
            onChangeHasSavePaypal={onChangeHasSavePaypal}
          />
        </MethodsContainer>
        {showMoreMethods && (
          <MoreMethodsContainer>
            <StyledCollapse
              items={[
                {
                  label: t('schedulePaymentMethod.more'),
                  children: (
                    <MethodsContainer>
                      <StripeRadioButton />
                      <PaypalRadioButton
                        value={PaymentMethodEnum.PAYPAL}
                        paymentMethod={paymentMethod}
                        hasSavePaypal={hasSavePaypal}
                        onChangeHasSavePaypal={onChangeHasSavePaypal}
                      />
                    </MethodsContainer>
                  ),
                },
              ]}
            />
          </MoreMethodsContainer>
        )}
      </StyledRadioGroup>
    </Container>
  )
}
