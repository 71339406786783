import { FC, useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { FullScreenModal } from 'src/Components/FullScreenModal'
import { ScheduleSteps } from 'src/Components/PrimaryScheduleTabs'
import { ScheduleModalsTitle } from 'src/Components/ScheduleModalsTitle'
import { useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getSortedGenres } from 'src/Redux/genres-process/selectors'
import { Genre } from 'src/Types'
import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

import { TiktokScheduleGenresModalFooter } from './TiktokScheduleGenresModalFooter'
import { TiktokScheduleGenresModalGenresList } from './TiktokScheduleGenresModalGenresList'

interface Props {
  genres?: Genre[]
  onSave: (genres: Genre[]) => void
}

const ListContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 214px);
  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    padding-bottom: 16px;
    height: calc(100vh - 255px);
  }
`

const ListWrapper = styled.div`
  padding: 0px 16px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`

export const TiktokScheduleGenresModal: FC<Props> = ({
  genres = [],
  onSave,
}) => {
  const { setSearch } = useCustomSearchParams()

  const { t } = useTranslation()
  const allGenres = useAppSelector(getSortedGenres)
  const [value, setValue] = useState<string>('')
  const [selectedGenres, setSelectedGenres] = useState<Genre[]>([])
  const handleClose = (): void => {
    setSearch({ step: ScheduleSteps.SETTINGS, modal: null })
  }

  useEffect(() => {
    if (genres.length) {
      setSelectedGenres(
        genres.map((genre) => ({ id: genre.value, genre_name: genre.label })),
      )
    }
  }, [genres])

  const handleClickGenre = (id: number): void => {
    setSelectedGenres((prev) => {
      const isGenreSelected = prev.some((item) => item.id === id)
      if (isGenreSelected) {
        return prev.filter((item) => item.id !== id)
      }
      const genre = allGenres.find((item) => item.id === id)
      return genre ? [...prev, genre] : prev
    })
  }

  const handleSetValue = useCallback((text: string) => {
    setValue(text)
  }, [])

  return (
    <FullScreenModal
      showCloseButton
      open
      onCancel={handleClose}
      prefixCls='tiktok-genres'
    >
      <ScheduleModalsTitle
        title={t('scheduleCampaignPage.trackGenres')}
        placeholder={t('scheduleCampaignPage.enterGenreName')}
        setValue={handleSetValue}
      />
      <ListContainer>
        <ListWrapper>
          <TiktokScheduleGenresModalGenresList
            searchValue={value}
            onClick={handleClickGenre}
            genres={allGenres}
            selectedGenres={selectedGenres}
          />
        </ListWrapper>
      </ListContainer>
      <TiktokScheduleGenresModalFooter
        onClick={() => onSave(selectedGenres)}
        isDisabled={!selectedGenres.length}
      />
    </FullScreenModal>
  )
}
