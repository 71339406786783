import { FC } from 'react'

import styled from 'styled-components'

import { Roles } from 'src/Constants/enums'

import { Avatar } from '../Avatar'

import { SidebarUserInfo } from './Components/SidebarUserInfo'
import { SidebarSubscriptionPlan } from './Components/SidebarSubscriptionPlan'

const Wrapper = styled.div<{ $hide: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ $hide }) => ($hide ? '50px' : '240px')};
  gap: 16px;
  transform: ${({ $hide }) => ($hide ? 'translateX(-240px)' : 'translateX(0)')};
  transition: transform 1s ease-in-out;
`

interface SidebarUserInfoProps {
  hide: boolean
  role: Roles
  firstName?: string
  userCover?: string
  lastName?: string
  planName?: string
}

export const SidebarUserInfoContainer: FC<SidebarUserInfoProps> = ({
  hide,
  role,
  firstName,
  userCover,
  lastName,
  planName,
}) => (
  <Wrapper $hide={hide}>
    <Avatar src={userCover} />
    <div>
      <SidebarUserInfo
        role={role}
        firstName={firstName ?? ''}
        lastName={lastName ?? ''}
      />
      {planName && <SidebarSubscriptionPlan planName={planName} />}
    </div>
  </Wrapper>
)
