import { FC, Suspense } from 'react'

import { Route, Routes } from 'react-router-dom'

import { APP } from 'src/Configs/App'
import SignUp from 'src/Containers/SignUp/SignUp'
import ForgotPassword from 'src/Containers/ForgotPasswordPage'
import ResetPassword from 'src/Containers/ResetPassword'
import ExpiredLinkPage from 'src/Containers/ui/ExpiredLinkPage'
import InternalServerError from 'src/Containers/ui/InternalServerError'
import Registration from 'src/Containers/Registration'
import ReferralLinkNotExist from 'src/Containers/ReferralLinkNotExist/index'
import LogIn from 'src/Containers/LogIn/Login'
import { SearchTrackByPlatform } from 'src/Containers/SearchTrackByPlatform'
import { FirstChosenTrack } from 'src/Containers/FirstChosenTrack'
import { SignupWithFirstTrack } from 'src/Containers/SignupWithFirstTrack'
import { FirstCreateCampaign } from 'src/Containers/FirstCreateCampaign'

import {
  signUpCurator,
  forgotPass,
  reset,
  create,
  spotify,
  errors,
  login,
  signUpCreator,
  internalServerError,
  referralLinkNotExist,
  signUpArtist,
} from './routes'

const PublicComponents: FC = () => (
  <Routes>
    <Route Component={LogIn} path='/' />
    <Route Component={LogIn} path={login} />
    <Route Component={SignUp} path={signUpCurator} />
    <Route Component={ForgotPassword} path={forgotPass} />
    <Route Component={ResetPassword} path={`${reset}/:uuid`} />
    <Route Component={ExpiredLinkPage} path={errors} />
    <Route Component={InternalServerError} path={internalServerError} />
    <Route
      element={
        <Suspense>
          <FirstCreateCampaign />
        </Suspense>
      }
      path={create}
    />
    <Route
      element={
        <Suspense>
          <SearchTrackByPlatform />
        </Suspense>
      }
      path={`${create}/:platform`}
    />
    <Route
      element={
        <Suspense>
          <FirstChosenTrack />
        </Suspense>
      }
      path={`${create}/:platform/:trackId`}
    />
    <Route
      element={
        <Suspense>
          <SignupWithFirstTrack />
        </Suspense>
      }
      path={`${create}/:platform/:trackId${signUpArtist}`}
    />
    <Route Component={Registration} path={signUpCreator} />
    <Route Component={ReferralLinkNotExist} path={referralLinkNotExist} />
    <Route
      Component={() => {
        window.location.href = APP.SPOTIFY_AUTH_API
        return null
      }}
      path={spotify}
    />
    <Route Component={LogIn} path='*' />
  </Routes>
)

export default PublicComponents
