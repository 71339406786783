import styled from 'styled-components'

import { MAX_MOBILE_WIDTH } from 'src/Styled/variables'

export const SignUpContainer = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background-color: black;
  position: absolute;
`

export const InnerSignUpContainer = styled.div`
  margin: auto;
`

export const SignUpLogo = styled.div`
  width: 151px;
  margin: 0 auto;
  margin-bottom: 16px;
`

export const SignUpBlock = styled.div`
  margin: auto;
  border-radius: 8px;
  padding: 32px;
  max-width: 358px;
  width: 100%;
  background-color: ${({ theme }) => theme.common.greyBg};

  @media screen and (min-width: ${MAX_MOBILE_WIDTH}) {
    padding: 64px;
    max-width: 600px;
  }
`

export const SignUpTitle = styled.div`
  font-family: 'Segoe UI';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: ${({ theme }) => theme.common.secondaryBg};
  text-align: center;
  margin-bottom: 8px;
`

export const SignUpSubTitle = styled.div`
  font-family: 'Segoe UI';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.common.secondaryBg};
  margin-bottom: 32px;
  text-align: center;
`

export const SafetyBlock = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  gap: 8px;
  > svg {
    margin-top: 4px;
  }
  margin-top: 8px;
  margin-bottom: 32px;
`

export const InfoText = styled.div`
  font-family: 'Segoe UI';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.common.secondaryBg};
`

export const Condition = styled.div`
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.common.secondaryBg};

  & > a {
    cursor: pointer;
    color: ${({ theme }) => theme.common.secondaryBg};
    text-decoration: none;
    border-bottom: 1px solid ${({ theme }) => theme.common.secondaryBg};
  }
`

export const AlreadyHaveAccount = styled.div`
  text-align: center;
  font-family: 'Segoe UI';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 8px;

  a {
    color: ${({ theme }) => theme.common.secondaryBorder};
    text-decoration: none;
    border-bottom: 1px solid ${({ theme }) => theme.common.secondaryBorder};
  }
`
